import type { ReactChild, ReactNode } from 'react';
import React from 'react';
import DOMPurify from 'dompurify';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { N40A, N50A, N30, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { SECTION_MESSAGE_APPEARANCE_TYPES } from '../constants';
import type { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div<{ isCard: boolean }>`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	max-width: ${({ isCard }) => (isCard ? '100%' : '95%')};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const SectionMessageTitle = styled.div`
	font: ${token('font.heading.small')};
	margin-top: 0;
	color: ${token('color.text')};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const SectionMessageBody = styled.div<{ withTitle: boolean }>`
	margin-top: ${({ withTitle }) => (withTitle ? `${token('space.100', '8px')}` : '0')};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const SectionMessageHeaderButton = styled.div<
	Pick<EditionSectionMessageProps, 'appearanceType'> & { isCard: boolean }
>`
	display: flex;
	justify-content: flex-end;
	position: absolute;
	top: ${({ appearanceType }) =>
		appearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT
			? `${token('space.400', '32px')}`
			: `${token('space.200', '16px')}`};
	right: ${({ isCard, appearanceType }) => {
		if (isCard && appearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT) {
			return `${token('space.400', '32px')}`;
		} else if (isCard) {
			return `${token('space.200', '16px')}`;
		} else if (appearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT) {
			return `${token('space.600', '48px')}`;
		} else {
			return `${token('space.300', '24px')}`;
		}
	}};
`;

type ButtonGroupAlignment = 'left' | 'right';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const SectionMessageActionRow = styled.div<{
	buttonGroupAlignment?: ButtonGroupAlignment;
}>`
	display: flex;
	margin-top: ${token('space.200', '16px')};

	${({ buttonGroupAlignment }) =>
		buttonGroupAlignment === 'right' &&
		`
    justify-content: flex-end;

    > * {
      flex-flow: row-reverse;
    }
  `}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- To migrate as part of go/ui-styling-standard
const SectionMessageFooter = styled.div`
	margin-top: ${token('space.200', '16px')};
	margin-bottom: ${token('space.200', '16px')};
	padding-top: ${token('space.150', '12px')};
	border-top: 2px solid ${token('color.border', N30)};
`;
// TODO(brizvash): non-convertable to compiled
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const SectionMessageContainer = styled.div<
	Pick<EditionSectionMessageProps, 'appearanceType' | 'withTasteAndToneStyling'>
>`
	background-color: ${({ withTasteAndToneStyling }) =>
		withTasteAndToneStyling ? null : `${token('elevation.surface.overlay', N0)}`};

	border-radius: 3px;
	display: flex;
	flex-grow: 1;

	margin: ${({ withTasteAndToneStyling }) => (withTasteAndToneStyling ? '0' : '0 auto')};

	${({ appearanceType }) => {
		switch (appearanceType) {
			case SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT:
				// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
				return css`
					padding: ${token('space.200', '16px')} ${token('space.300', '24px')};
					box-shadow: ${token(
						'elevation.shadow.overlay',
						`0 1px 1px ${N50A}, 0 0 1px 1px ${N40A}`,
					)};
					max-width: 710px;
				`;

			case SECTION_MESSAGE_APPEARANCE_TYPES.FLAT:
				// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
				return css`
					padding: ${token('space.200', '16px')} ${token('space.300', '24px')};
					max-width: 710px;
				`;

			case SECTION_MESSAGE_APPEARANCE_TYPES.INLINE:
				// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
				return css`
					font-size: 14px;
				`;

			case SECTION_MESSAGE_APPEARANCE_TYPES.INLINE_MODAL:
				// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
				return css`
					max-width: 400px;
					${SectionMessageTitle} {
						font: ${token('font.heading.medium')};
						margin-top: ${token('space.0', '0')};
						padding-bottom: ${token('space.200', '16px')};
					}

					${SectionMessageActionRow} {
						margin-top: 96px;
						display: flex;
					}
				`;
		}
	}}
`;

export type EditionSectionMessageProps = {
	actions: ReactNode;
	appearanceType?: SECTION_MESSAGE_APPEARANCE_TYPES;
	body: ReactNode;
	edition?: ConfluenceEdition;
	footer?: ReactChild | null;
	title?: ReactChild;
	headerButton?: ReactChild;
	withTasteAndToneStyling?: boolean;
	buttonGroupAlignment?: ButtonGroupAlignment;
};

/**
 * @deprecated Please reach out to #cc-editions for details before use. Please read https://hello.atlassian.net/wiki/spaces/CE2/pages/2797298237/Contribution+Consumption+Guidelines+For+Common+Feature+Gate+Upsell+Component
 */
export const EditionSectionMessage = ({
	actions,
	appearanceType,
	body,
	footer,
	title,
	headerButton,
	withTasteAndToneStyling = false,
	buttonGroupAlignment = 'left',
}: EditionSectionMessageProps) => {
	const withTitle = Boolean(title);
	const isCard = Boolean(buttonGroupAlignment === 'right');

	const updatedAppearanceType =
		appearanceType ||
		(withTasteAndToneStyling
			? SECTION_MESSAGE_APPEARANCE_TYPES.INLINE
			: SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT);

	return (
		<SectionMessageContainer
			appearanceType={updatedAppearanceType}
			data-testid="edition-section-message"
			withTasteAndToneStyling={withTasteAndToneStyling}
		>
			<ContentContainer isCard={isCard}>
				{withTitle && <SectionMessageTitle>{title}</SectionMessageTitle>}
				{typeof body === 'string' ? (
					<SectionMessageBody
						withTitle={withTitle}
						// See https://hello.atlassian.net/browse/APOLLO-3511
						// and other code tagged with this ticket
						dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
					/>
				) : (
					<SectionMessageBody withTitle={withTitle}>{body}</SectionMessageBody>
				)}
				{headerButton && (
					<SectionMessageHeaderButton isCard={isCard} appearanceType={updatedAppearanceType}>
						{headerButton}
					</SectionMessageHeaderButton>
				)}
				{actions && (
					<SectionMessageActionRow
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className="actionButtons"
						buttonGroupAlignment={buttonGroupAlignment}
					>
						{actions}
					</SectionMessageActionRow>
				)}
				{footer && <SectionMessageFooter>{footer}</SectionMessageFooter>}
			</ContentContainer>
		</SectionMessageContainer>
	);
};
